import { countTotalAllDivs } from "./countTotal.js";
import { displayMessage } from "../alert/messageFunc.js";
import { translate } from "./translations.js";

$('body').on('click', '.form_for_js_in_div .button_for_js_in_div', function (event) {
    var button = $(this);
    var button_name = button.attr('name');
    var formdiv = $(this).closest('.form_for_js_in_div');
    var action = formdiv.data('action');
    //console.log(action);

    var post_data = {};
    //add all inputs to post_data
    var inputs = formdiv.find('input');
    //console.log(inputs);
    for (var i = 0; i < inputs.length; i++) {
        const input = inputs.eq(i);
        // console.log(input)
        if (input.attr(`type`) == `radio` || input.attr(`type`) == `checkbox`) {
            // console.log(input);
            // console.log(input.prop("checked"));
            if (!input.prop("checked")) {//если не чекед то не отправляем
                // console.log(`not checked`);
                continue;
            }
        }

        var inputname = input.attr('name');
        if (inputname == 'undefined' || inputname == undefined) {
            continue;
        }
        var inputvalue = input.val();
        if (inputname && inputname.includes('[]')) {
            if (post_data[inputname] == undefined) {
                post_data[inputname] = [];
            }
            post_data[inputname].push(inputvalue);
        } else {
            //reverse for ip_protect
            if (inputname == 'ip_protect') {
                inputvalue = inputvalue == '1' ? '0' : '1';
            }
            post_data[inputname] = inputvalue;
        }
    }

    //add all selects to post_data
    var selects = formdiv.find('select');
    for (var i = 0; i < selects.length; i++) {
        const select = selects.eq(i);
        var selectname = select.attr('name');
        var selectvalue = select.val();
        if (selectname && selectname.includes('[]')) {
            if (post_data[selectname] == undefined) {
                post_data[selectname] = [];
            }
            post_data[selectname].push(selectvalue);
        } else {
            post_data[selectname] = selectvalue;
        }
    }


    var recaptcha = formdiv.find('textarea.g-recaptcha-response');
    if (recaptcha) {
        post_data['g-recaptcha-response'] = recaptcha.val();
    }
    //var post_data;

    post_data.button_name = button_name;
    // console.log('https://' + window.location.host + action);
    // console.log(post_data);
    //перед отправкой отключаем кнопку и добавляем анимацию лоада
    button.prop("disabled", true);
    if (button.hasClass('animation-loading')) {
        button.addClass('loading');
    }
    if (button.hasClass('hide-loading')) {
        button.hide();
    }
    let url = 'https://' + window.location.host + action;
    //alert(JSON.stringify([url, post_data])); 
    var posting = $.post(url, post_data);
    posting.done(function (data, textStatus, request) {
        try {
            // Display message if available in the initial response
            if (data.message && data.message.text) {
                displayMessage(
                    translate(data.message.text), 
                    data.message.type, 
                    translate(data.message.title),
                    data.message.timeout // Add timeout parameter
                );
            }

            if (data.task_id) {
                // Use retry_after and retry_count if available, otherwise use default values
                const retryInterval = data.retry_after ? data.retry_after * 1000 : 5000;
                const maxRetries = data.retry_count || 10;
                
                if (data.content_html) {
                    $('body').append(data.content_html);
                }
                // Schedule the first poll after retryInterval
                setTimeout(() => {
                    pollTaskStatus(data.task_id, formdiv, button, action, post_data, 0, maxRetries, retryInterval);
                }, retryInterval);
            } else {
                handleResponse(data, action, formdiv, post_data, button);
                cleanup();
            }
        } catch (error) {
            console.error(error);
            cleanup();
        }
    });

    posting.fail(function (jqXHR, textStatus, error) {
        console.error(`error posting`, action);
        console.error(jqXHR, textStatus, error);
        $('.response_block_close_js_answer').remove();
        $('div.blackout').remove();
        cleanup();
    })


    function pollTaskStatus(taskId, formdiv, button, action, post_data, retryCount = 0, maxRetries = 10, retryInterval = 5000) {
        const pollUrl = `https://${window.location.host}/engine/function/long_running_process.php?task_id=${taskId}`;

        $.get(pollUrl)
            .done(function (response) {
                // Display message if available
                if (response.message && response.message.text) {
                    displayMessage(
                        translate(response.message.text), 
                        response.message.type, 
                        translate(response.message.title),
                        response.message.timeout // Add timeout parameter
                    );
                }

                // Use response values if provided, otherwise use the original parameters
                const currentMaxRetries = response.retry_count || maxRetries;
                const currentRetryInterval = response.retry_after ? response.retry_after * 1000 : retryInterval;

                if (response.status === 'completed') {
                    handleResponse(response, action, formdiv, post_data, button);
                    cleanup();
                } else if ((response.status === 'processing' || response.status === 'error') && retryCount < currentMaxRetries) {
                    // Retry if processing or error, and within retry limit
                    setTimeout(() => pollTaskStatus(taskId, formdiv, button, action, post_data, retryCount + 1, currentMaxRetries, currentRetryInterval), currentRetryInterval);
                } else {
                    console.log('Task not found or maximum retries reached', taskId);
                    displayMessage(translate('Error sending request'), 'error', translate('Error'));
                    cleanup();
                }
            })
            .fail(function (jqXHR, textStatus, error) {
                console.error(`Error polling`, pollUrl, error);
                if (retryCount < maxRetries) {
                    // Retry on failure if within retry limit
                    setTimeout(() => pollTaskStatus(taskId, formdiv, button, action, post_data, retryCount + 1, maxRetries, retryInterval), retryInterval);
                } else {
                    console.error(`max retries reached for task ${taskId}`);
                    displayMessage(translate('Error sending request'), 'error', translate('Error'));
                    cleanup();
                }
            });
    }

    function cleanup() {
        button.prop("disabled", false);
        button.removeClass('loading');
        button.find('.loading-div__mini_b').remove();
        toSVG();
    }


    function handleResponse(data, action, formdiv, post_data, button) {
        if (data.error) {
            $('body').append(data.error);
            return;
        }

        $('.response_block_close_js_answer').remove();
        $('div.blackout').remove();

        if (action == '/engine/function/user_trades_post.php') {
            if (formdiv.hasClass('modal') && post_data.trade_type == 'sell') {
                if (data.response && data.response.trade) {
                    $('#js__sell_up').closest('.bet-content').show();
                    $(`input[name=assetid][value=${post_data.assetid}]`).closest('.pay__item.setting').remove();
                    $('#js__sell_up').find('.bet-content-show').first().append(data.response.trade);
                    formdiv.find('a.js-close')[0].click();
                    countTotalAllDivs();
                    return;
                } else {
                    return;
                }
            }

            if (formdiv.hasClass('modal') || post_data['cancel_item_selling']) {
                if (!data.response) {
                    return;
                }
                if (!data.response.user_trade_id) {
                    return;
                }
                if (!data.response.price) {
                    $(`input[value="${data.response.user_trade_id}"]`).closest('.pay__item.setting').remove();
                    formdiv.find('a.js-close')[0].click();
                    countTotalAllDivs();
                    return;
                }
                let $item_div = $(`input[value="${data.response.user_trade_id}"]`).closest('.pay__item.setting');
                $item_div.find('[data-price]').data('price', data.response.price);
                $item_div.find('.pay__item-sum .money-amount .js_amount').text(data.response.price);
                formdiv.find('a.js-close')[0].click();
                countTotalAllDivs();
                return;
            }
        }

        if (action.includes('/engine/function/give_achievement_bonus.php')) {
            formdiv.find('.js_response_to_div').html(data);
            return;
        }

        if (data.response && data.response.return_url) {
            window.location.assign(data.response.return_url);
            return;
        }

        if (data.content_html) {
            $('body').append(data.content_html);
        }
    }


    if ($(this).hasClass('response_block_close_js')) {
        //console.log('closing by class');
        $('div.blackout').remove();
        formdiv.remove();
        return;
    }
});
